<template>
    <div class="outstanding-invoices-page py-5">
        <div class="container-fluid">
            <h4 class="single-page-header mt-5 text-start">
                CONTACT INFORMATION
            </h4>
            <div class="row w-100 mx-0">
                <div class="col-md-12">
                    
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideFillter from '../components/products/SideFillter.vue';
import ProductCard from '../components/products/ProductCard.vue';
export default {
    name: 'HomePage',
    components: {
        SideFillter,
        ProductCard
    }
}
</script>
  
<style lang="scss">
.outstanding-invoices-page {
    .card {
        border-radius: 25px;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .table {
        td {
            color: #878787;
            padding-top: 20px;
            padding-bottom: 20px;



        }

    }
}
</style>
    