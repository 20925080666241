<template>
  <div class="home-page">
    <div class="row d-flex">
      <div class="col-md-3 left-panel pt-5 pe-0">
        <SideFillter></SideFillter>
      </div>
      <div class="col-md-9 pt-5">
        
        <div class="row w-100 mx-0 mt-4 products-row">

          <div class="col-md-3 mb-3" v-for="(product) in this.$store.state.productsData.products.slice(0, productsToShow)"
            v-bind:key="product.id">
            <ProductCard :product="product"></ProductCard>
          </div>
        </div>
        <div class="row w-100 mx-0 d-flex align-items-center justify-content-center py-5">
          <div class="col-md-5">
            <button class="btn btn-see-more-products" v-if="loadMoreProductsBtn" @click="loadMoreProducts(5)">See More Products</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideFillter from '../components/products/SideFillter.vue';
import ProductCard from '../components/products/ProductCard.vue';
export default {
  name: 'HomePage',
  data() {
    return {
      loadMoreProductsBtn: true,
      productsToShow: 5
    };
  },
  components: {
    SideFillter,
    ProductCard
  },
  mounted() {
    this.$store.dispatch('productsData/getProducts')
  },
  methods: {
    loadMoreProducts(increce) {
      this.productsToShow += increce;
      if(this.productsToShow >= this.$store.state.productsData.products.length){
        this.loadMoreProductsBtn = false;
      }
    }
  }
}
</script>

<style lang="scss">
.home-page {

  .left-panel {
    background: linear-gradient(0deg, #E1EDFF 0%, rgba(158, 197, 255, 0) 141.12%);
  }

  .products-row {
    padding-right: 5rem !important;


  }

  .btn-see-more-products {
    height: 50px;
    border-radius: 50px;
    background-color: #EA0A2A;
    color: #fff;
    width: 100%;
  }
}
</style>
  