const productsData = {
    namespaced: true,
    state() {
        return {
            products: [
                {
                    id: 1,
                    name: 'TEST PRODUCT ONE',
                    image: 'none',
                    price: 23.00,
                    unit: 'PKT'
                },
                {
                    id: 2,
                    name: 'TEST PRODUCT TWO',
                    image: 'none',
                    price: 16.00,
                    unit: 'PKT'
                }
            ]
        }
    },
    getters: {
    },
    mutations: {
        // set products
        setProducts(state, products) {
            state.products = products;
        },

    },
    actions: {
        // get products
        async getProducts(context) {
            const products = [];
            for (let i = 1; i <= 20; i++) {
                const randomPrice = (Math.random() * 100).toFixed(2); // Random price between 0 and 100 with 2 decimal places
                const product = {
                    id: i,
                    name: `TEST PRODUCT ${i}`,
                    image: 'none',
                    price: parseFloat(randomPrice),
                    unit: 'PKT'
                };
                products.push(product);
            }
            context.commit('setProducts', products);
        },
    }
}

export default productsData;