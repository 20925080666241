const cartsData = {
    namespaced: true,
    state() {
        return {
            products: JSON.parse(localStorage.getItem('cartItems')) || []
        }
    },
    getters: {
    },
    mutations: {
        // set products
        setCartProducts(state, item) {
            state.products.push(item);
            localStorage.setItem('cartItems', JSON.stringify(state.products));
        },

    },
    actions: {
        // get products
        async setCartProducts(context,payload) {
            const cartItem = {
                product: this.state.productsData.products.find(product => product.id === payload.productId),
                qty : payload.qty
            };
            context.commit('setCartProducts', this.state.productsData.products.find(product => product.id === payload.productId));
        },
    }
}

export default cartsData;