<template>
    <div class="header">
        <div class="container-fluid">
            <div class="top-header d-lg-flex align-items-center justify-content-end py-3 d-none">
                <a href="#" class="me-5"><i class="bi bi-person-circle me-2"></i>Hello Mark</a>
                <label for="">Select Language :</label>
                <div class="dropdown ms-2 me-4">
                    <button class="btn btn-outline-primary btn-sm dropdown-toggle lang-btn" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        English
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#">Swidish</a></li>
                        <li><a class="dropdown-item" href="#">Franch</a></li>
                    </ul>
                </div>
                <a href="#" class="link fw-bold ms-4"><i class="bi bi-house-door me-2"></i> Go Back</a>

            </div>
            <div class="main-header py-2">
                <div class="row">
                    <div class="col-lg-4 col-6 d-flex align-items-center justify-content-start">
                        <img src="../../assets/images/logo.png" alt="">
                    </div>
                    <div class="col-lg-none col-6 d-flex align-items-center justify-content-end">
                        <div class="cart-btn-section">
                            <div class="dropdown ms-2 me-3">
                                
                                <Cart></Cart>
                                
                            </div>
                        </div>
                        <button class="btn side-panel-btn">side</button>
                    </div>
                    <div class="col-md-8 d-lg-flex d-none align-items-center justify-content-end ">
                        <router-link :to="{ name: 'home-page' }" class="me-2 px-2 main-link" :class="(this.$route.name == 'home-page') ? 'active' : ''">Home</router-link>
                        <router-link :to="{ name: 'my-orders' }" class="me-2 px-2 main-link" :class="(this.$route.name == 'my-orders') ? 'active' : ''">My Orders</router-link>
                        <router-link :to="{ name: 'outstanding-invoices' }" class="me-2 px-2 main-link" :class="(this.$route.name == 'outstanding-invoices') ? 'active' : ''">Outstanding Invoices</router-link>
                        <router-link :to="{ name: 'contact-us' }" class="me-2 px-2 main-link" :class="(this.$route.name == 'contact-us') ? 'active' : ''">Contact Us</router-link>
                        <div class="cart-btn-section">
                            <div class="dropdown ms-2 me-3">
                                
                                <Cart></Cart>
                                
                            </div>
                        </div>
                        <a href="#" class="ps-2 main-link logout"><i class="bi bi-box-arrow-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="search-section py-4">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-md-5">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="SEARCH">
                            <span class="input-group-text pe-3"><i class="bi bi-search"></i></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="notice">
                <div class="row d-flex align-items-center justify-content-center">
                    <div class="col-md-7 notice-div bg-white text-black py-2 px-5">
                        Kindly order before 5.00p.m. for next day delivery except for Sunday and Public Holiday. Any orders
                        received on Sunday / Public Holiday will only be processed by office on the next working day. An
                        email notification will be sent to you when order is complete
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Cart from '../../components/cart/cart';
export default {
  name: 'HelloWorld',
  components: {
    Cart,
  }
}
</script>

<style lang="scss">
.header {
    background-color: #0033A0;
    color: #fff;
    font-family: 'Inria Sans', sans-serif;
    letter-spacing: 0.7px;
    font-weight: 300;
    max-height: 320px;

    a {
        color: #fff;
    }

    .top-header{
        .lang-btn{
            border-color: #fff;
            color: #fff;
        }
    }

    .main-header {
        .main-link {
            font-size: 20px;

            &.active {
                color: #FACD01;
                border: 1px solid #FACD01;
                border-radius: 5px;
            }

            &.logout {
                color: #FACD01;
                font-size: 23px;
            }
        }

        .cart-btn-section {
            .cart-btn {
                background-color: transparent;
                color: #FACD01;
                font-size: 23px;

                &:after {
                    display: none;
                }

                &:focus {
                    border-color: transparent;
                }

                .cart-count {
                    background: #FACD01;
                    width: 20px;
                    height: 20px;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 13px;
                    margin-left: -4px;
                    padding: 0;
                }
            }
        }
    }

    .search-section {
        input {
            height: 55px;
            border-radius: 50px;
            padding-left: 20px;
            background-color: #A2BDEB;
            border-right: 0 !important;
            border-color: #A2BDEB;
            color: #0033A0;

            &:focus {
                box-shadow: none;

            }

            &::-webkit-input-placeholder {
                color: #0033A0;
            }

            &::-moz-placeholder {
                color: #0033A0;
            }

            &:-ms-input-placeholder {
                color: #0033A0;
            }

            &:-moz-placeholder {
                color: #0033A0;
            }
        }

        .input-group-text {
            height: 55px;
            border-radius: 50px;
            padding-left: 20px;
            background-color: #A2BDEB;
            border: none !important;
            color: #0033A0;
            font-weight: 600;
        }

        
    }

    .notice-div{
            border-radius: 12px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            font-size: 16px;
            z-index: 99999;
        }
}</style>