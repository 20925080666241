<template>
    <div class="my-orders-page py-5">
        <div class="container-fluid">
            <h4 class="single-page-header mt-5 text-start">
                MY ORDERS
            </h4>
            <div class="row">
                <div class="col-md-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active px-5" id="pending-orders-tab" data-bs-toggle="tab"
                                data-bs-target="#pending-orders-tab-pane" type="button" role="tab"
                                aria-controls="pending-orders-tab-pane" aria-selected="true">Pending Orders</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link px-5" id="order-history-tab" data-bs-toggle="tab"
                                data-bs-target="#order-history-tab-pane" type="button" role="tab"
                                aria-controls="order-history-tab-pane" aria-selected="false">Order History</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <!-- my orders -->
                        <div class="tab-pane fade show active card" id="pending-orders-tab-pane" role="tabpanel"
                            aria-labelledby="pending-orders-tab" tabindex="0">
                            <table class="table table-striped my-orders">
                                <thead>
                                    <tr>
                                        <th scope="col">Order Number</th>
                                        <th scope="col">Delivery Date</th>
                                        <th scope="col">Total Amount</th>
                                        <th scope="col">More</th>
                                        <th scope="col">Pay</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                        <td><a href="#" class="pay-link">PAY</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                        <td><a href="#" class="pay-link">PAY</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                        <td><a href="#" class="pay-link">PAY</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                        <td><a href="#" class="pay-link">PAY</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                        <td><a href="#" class="pay-link">PAY</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                        <td></td>
                                    </tr>



                                </tbody>
                            </table>
                        </div>
                        <div class="tab-pane fade card" id="order-history-tab-pane" role="tabpanel"
                            aria-labelledby="order-history-tab" tabindex="0">
                            <table class="table table-striped my-orders">
                                <thead>
                                    <tr>
                                        <th scope="col">Order Number</th>
                                        <th scope="col">Delivery Date</th>
                                        <th scope="col">Total Amount</th>
                                        <th scope="col">More</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                    </tr>
                                    <tr>
                                        <td>SW23-009989</td>
                                        <td>04/01/2024</td>
                                        <td>$4.78</td>
                                        <td><a href="#" class="details-link">DETAIL</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideFillter from '../components/products/SideFillter.vue';
import ProductCard from '../components/products/ProductCard.vue';
export default {
    name: 'HomePage',
    components: {
        SideFillter,
        ProductCard
    }
}
</script>
  
<style lang="scss">
.my-orders-page {

    .nav-link {
        background-color: #A5A5A5;
        color: #fff;

        &.active {
            background-color: #0033A0;
            color: #fff;
        }
    }

    .tab-pane {
        border-radius: 25px;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-top-left-radius: 0px;
    }

    .table {
        td {
            color: #878787;
            padding-top: 20px;
            padding-bottom: 20px;



        }

    }


    a.details-link {
        background-color: #fff;
        border: 1px solid #878787;
        color: #878787;
        padding: 2px 14px;
        font-size: 13px;
    }

    a.pay-link {
        background-color: #EA0A2A;
        border: 1px solid #EA0A2A;
        color: #fff;
        padding: 2px 14px;
        font-size: 13px;
    }


}
</style>
    