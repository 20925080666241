<template>
    <div class="product-item">

        <div class="image card up-card">
            <img src="../../assets/images/products/p-1.png" alt="">
        </div>
        <div class="card down-card">
            <div class="details py-3">
                <h5 class="name">
                    {{ product.name }}
                </h5>
                <h5 class="price">${{ product.price.toFixed(2) }}</h5>
                <div class="qty">
                    <h5 class="qty-label">{{ product.unit }}</h5>
                    <div class="qty-function d-flex align-items-center justify-content-center">
                        <button class="qtybtn min" @click="(productQty > 1) ? productQty -= 1 : productQty = 0">-</button>
                        <input type="text" readonly class="qty-input form-control" ref="qty" :value="productQty">
                        <button class="qtybtn max" @click="productQty += 1">+</button>
                    </div>
                </div>
                <button class="btn add-to-cart-btn mt-3" @click="addToCart(product.id)"><i class="bi bi-cart"></i> Add To
                    Cart</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductCard',
    data() {
        return {
            productQty: 1,
        };
    },
    props: {
        product: Object
    },
    components: {
    },
    methods: {
        addToCart(productId) {
            const qty = this.$refs.qty.value;
            this.$store.dispatch('cartsData/setCartProducts',{productId,qty} )
        }
    }
}
</script>

<style lang="scss">
.product-item {
    cursor: pointer;

    .image {
        img {
            max-width: 100%;
        }
    }

    .up-card {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .down-card {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    .details {
        .name {
            font-size: 16px;
            font-weight: 500;
        }

        .price {
            color: #EA0A2A;
            font-size: 20px;
            font-weight: 600;
        }

        .qty {
            .qty-label {
                font-size: 14px;
            }

            .qtybtn {
                width: 55px;
                border: none;
                background: #0033A0;
                height: 32px;
                color: #FACD01;
                font-size: 22px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.min {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &.max {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            .qty-input {
                width: 66px;
                border-radius: 0;
                text-align: center;
            }
        }
    }

    .add-to-cart-btn {
        border-radius: 50px;
        background-color: transparent;
        border: 1px solid #000;
        width: 90%;

        &:hover {
            background-color: #EA0A2A;
            color: #fff;
        }
    }
}</style>