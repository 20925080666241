<template>
    <div class="fillter">
        <div class="item">
            <div class="mb-4">
                <label for="delivery_date" class="form-label w-100 text-start mb-0">Delivery Date</label>
                <input type="date" class="form-control" id="delivery_date">
            </div>
            <div class="seperator">
            </div>
        </div>
        <div class="item">
            <div class="mb-5">
                <label for="sorting" class="form-label w-100 text-start mt-4 mb-0">Sorting</label>
                <select class="form-select form-select-lg mb-3" id="sorting" aria-label="Large select example">
                    <option selected>Default Sorting</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="seperator">
            </div>
        </div>

        <div class="item products">
            <div class="mb-4">
                <label class="form-label w-100 text-start mt-4 mb-0">Products</label>
                <a href="#" class="option text-start active"><i class="bi bi-box-fill me-2"></i>RECENT PRODUCTS</a>
                <a href="#" class="option text-start"><i class="bi bi-box-fill me-2"></i>MY PRODUCTS</a>
                <a href="#" class="option text-start"><i class="bi bi-box-fill me-2"></i>ALL PRODUCTS</a>
            </div>
            <div class="seperator">
            </div>
        </div>

        <div class="item categories">
            <div class="mb-4">
                <label class="form-label w-100 text-start mt-4 mb-0">Categories</label>
                <a href="#" class="option text-start">01-ACCESSORIES</a>
                <a href="#" class="option text-start">02-CONTROL STRATIONS</a>
                <a href="#" class="option text-start">03-PLUGS</a>
                <a href="#" class="option text-start">BEANCURD SERIES</a>
                <a href="#" class="option text-start">MORE...</a>
            </div>
        </div>

    </div>
</template>

<script>
import Cart from '../../components/cart/cart';
export default {
    name: 'SideFillter',
    components: {
        Cart,
    }
}
</script>

<style lang="scss">
.fillter {
    padding-left: 5rem !important;
    height: 100%;
    .item {
        position: relative;

        label {
            font-family: 'Kalam', cursive;
            font-size: 25px;
            font-weight: 600;
        }

        input {
            background-color: #fff;
            border-right: 0px;
            border: none;
            height: 45px;
            &::-webkit-input-placeholder {
                    color: #D1D1D1;
                }

                &::-moz-placeholder {
                    color: #D1D1D1;
                }

                &:-ms-input-placeholder {
                    color: #D1D1D1;
                }

                &:-moz-placeholder {
                    color: #D1D1D1;
                }
        }

        select{
            color: #8F8F8F;
            background-color: #fff;
            border-right: 0px;
            border: none;
            height: 45px;
        }

        .seperator {
            background-color: rgb(70, 69, 69);
            height: 1px;
            position: absolute;
            left: -100%;
            width: 200%;
        }

        &.products{
            .option{
                padding: 10px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                font-size: 17px;
                font-family: 'Kalam', cursive;
                color: #7B7B7B;
                &.active{
                    background-color: #fff;
                    color: #0033A0;
                }
                display: block;
            }
        }

        &.categories{
            .option{
                padding: 10px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                font-size: 17px;
                font-family: 'Kalam', cursive;
                color: #7B7B7B;
                &.active{
                    background-color: #fff;
                    color: #0033A0;
                }
                display: block;
            }
        }
    }

}
</style>