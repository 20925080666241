<template>
  <div class="home">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/headers/header'
import Footer from '../components/footers/footer'

export default {
  name: 'HomeView',
  components: {
    Header,
    Footer
  }
}
</script>
