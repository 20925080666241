<template>
    <div class="outstanding-invoices-page py-5">
        <div class="container-fluid">
            <h4 class="single-page-header mt-5 text-start">
                Outstanding Invoices
            </h4>
            <div class="row w-100 mx-0">
                <div class="col-md-12">
                    <div class="card p-">
                        <table class="table table-striped outstanding-invoices">
                            <thead>
                                <tr>
                                    <th scope="col">Invoice Number</th>
                                    <th scope="col">Invoice Date</th>
                                    <th scope="col">Balance Amount</th>
                                    <th scope="col">Total Due</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>SW23-009989</td>
                                    <td>04/01/2024</td>
                                    <td>$4.78</td>
                                    <td>$5.67</td>
                                </tr>
                                <tr>
                                    <td>SW23-009989</td>
                                    <td>04/01/2024</td>
                                    <td>$4.78</td>
                                    <td>$5.67</td>
                                </tr>
                                <tr>
                                    <td>SW23-009989</td>
                                    <td>04/01/2024</td>
                                    <td>$4.78</td>
                                    <td>$5.67</td>
                                </tr>
                                <tr>
                                    <td>SW23-009989</td>
                                    <td>04/01/2024</td>
                                    <td>$4.78</td>
                                    <td>$5.67</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideFillter from '../components/products/SideFillter.vue';
import ProductCard from '../components/products/ProductCard.vue';
export default {
    name: 'HomePage',
    components: {
        SideFillter,
        ProductCard
    }
}
</script>
  
<style lang="scss">
.outstanding-invoices-page {
    .card {
        border-radius: 25px;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .table {
        td {
            color: #878787;
            padding-top: 20px;
            padding-bottom: 20px;



        }

    }
}
</style>
    