<template>
    <div class="checkout-page py-5">
        <div class="container-fluid">
            <div class="row w-100 mx-0">
                <div class="col-md-8">
                    <div class="row w-100 mx-0">
                        <div class="col-md-9 px-0 d-flex align-items-center justify-content-start">
                            <h4 class="single-page-header mt-5 text-start">
                                SHOPPING CART - CHECKOUT
                            </h4>
                        </div>
                        <div class="col-md-3 px-0 d-flex align-items-center justify-content-start">
                            <button class="btn add-items mt-5 px-5">Add Items</button>
                        </div>
                        <div class="col-md-12 py-4">
                            <div class="row w-100 mx-0 item-headers">
                                <div class="col-md-4 text-start">
                                    <h5 class="title">PRODUCT</h5>
                                </div>
                                <div class="col-md-2 text-start">
                                    <h5 class="title">PRICE</h5>
                                </div>
                                <div class="col-md-1 text-start">
                                    <h5 class="title">UOM</h5>
                                </div>
                                <div class="col-md-2 text-start">
                                    <h5 class="title">QUANTITY</h5>
                                </div>
                                <div class="col-md-2 text-center">
                                    <h5 class="title">TOTAL</h5>
                                </div>
                            </div>
                            <!-- one item -->
                            <div class="row w-100 mx-0 item py-2">
                                <div
                                    class="col-md-4 pe-0 ps-1 item-details d-flex align-items-center justify-content-center">
                                    <button class="btn p-0 remove-btn me-3"><i class="bi bi-x-square-fill"></i></button>
                                    <img src="../assets/images/products/p-1.png" class="image" alt="">
                                    <div class="row text-start pt-2">
                                        <h5 class="name">COOKED & PEELED FRESH EGG</h5>
                                        <p>CEGG01A</p>
                                    </div>
                                </div>
                                <div class="col-md-2 d-flex align-items-center justify-content-start">
                                    <h5 class="price">$4.70</h5>
                                </div>
                                <div class="col-md-1 d-flex align-items-center justify-content-start">
                                    <h5 class="uom">PKT</h5>
                                </div>
                                <div class="col-md-2 ">
                                    <div class="qty mt-3">
                                        <div class="qty-function d-flex align-items-center justify-content-start">
                                            <button class="qtybtn min">-</button>
                                            <input type="text" readonly class="qty-input form-control" value="1">
                                            <button class="qtybtn max">+</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 d-flex align-items-center justify-content-center">
                                    <h5 class="total">$4.59</h5>
                                </div>
                            </div>

                            <!-- one item -->
                            <div class="row w-100 mx-0 item py-2">
                                <div
                                    class="col-md-4 pe-0 ps-1 item-details d-flex align-items-center justify-content-center">
                                    <button class="btn p-0 remove-btn me-3"><i class="bi bi-x-square-fill"></i></button>
                                    <img src="../assets/images/products/p-1.png" class="image" alt="">
                                    <div class="row text-start pt-2">
                                        <h5 class="name">COOKED & PEELED FRESH EGG</h5>
                                        <p>CEGG01A</p>
                                    </div>
                                </div>
                                <div class="col-md-2 d-flex align-items-center justify-content-start">
                                    <h5 class="price">$4.70</h5>
                                </div>
                                <div class="col-md-1 d-flex align-items-center justify-content-start">
                                    <h5 class="uom">PKT</h5>
                                </div>
                                <div class="col-md-2 ">
                                    <div class="qty mt-3">
                                        <div class="qty-function d-flex align-items-center justify-content-start">
                                            <button class="qtybtn min">-</button>
                                            <input type="text" readonly class="qty-input form-control" value="1">
                                            <button class="qtybtn max">+</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 d-flex align-items-center justify-content-center">
                                    <h5 class="total">$4.59</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 right-panel">
                    <h5 class="title mt-5 text-start mb-2">DELIVERY DATE</h5>
                    <input type="date" class="form-control" id="delivery_date">
                    <table border="1" class="table mt-4">
                        <tr>
                            <td class="text-start">MINIMUM SALES AMOUNT</td>
                            <td class="text-end blue-color">$50.00</td>
                        </tr>
                        <tr>
                            <td class="text-start">TOTAL OUTSTANDING</td>
                            <td class="text-end blue-color">$50.00</td>
                        </tr>
                    </table>

                    <div class="contact-details-form">
                        <h5 class="title mt-5 text-start mb-2">CONTACT DETAILS</h5>
                        <form class="row">
                            <div class="mb-3 col-12">
                                <input type="text" class="form-control" id="contact_name" placeholder="Contact Name">
                            </div>
                            <div class="mb-3 col-md-6">
                                <input type="text" class="form-control" id="mobile" placeholder="Mobile Number">
                            </div>
                            <div class="mb-3 col-md-6">
                                <input type="email" class="form-control" id="email" placeholder="Email Address">
                            </div>
                            <div class="col-md-12">
                                <textarea name="remarks" class="form-control" id="remarks" cols="30" rows="2"
                                    placeholder="Remarks"></textarea>
                            </div>
                        </form>
                    </div>

                    <div class="cart-totals">
                        <h5 class="title mt-5 text-start mb-2">CART TOTALS</h5>
                        <table border="1" class="table mt-4">
                            <tr>
                                <td class="text-start">TOTAL ITEMS</td>
                                <td class="text-end blue-color">2</td>
                            </tr>
                            <tr>
                                <td class="text-start">SUB TOTAL</td>
                                <td class="text-end blue-color">$11.00</td>
                            </tr>
                            <tr>
                                <td class="text-start">DELIVERY CHARGES</td>
                                <td class="text-end blue-color">$11.00</td>
                            </tr>
                            <tr>
                                <td class="text-start">WEEKEND DELIVERY CHARGES</td>
                                <td class="text-end blue-color">$11.00</td>
                            </tr>
                            <tr>
                                <td class="text-start">AMOUNT</td>
                                <td class="text-end blue-color">$11.00</td>
                            </tr>
                            <tr>
                                <td class="text-start">GST (8%)</td>
                                <td class="text-end blue-color">$11.00</td>
                            </tr>
                            <tr>
                                <td class="text-start ft-text pt-3"><b>TOTAL AMOUNT</b></td>
                                <td class="text-end full-total py-3">$18.00</td>
                            </tr>
                        </table>
                    </div>
                    <button type="submit" class="btn checkout-submit w-100 mt-2" data-bs-toggle="modal"
                        data-bs-target="#checkoutConfirmModal">Submit</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Checkout confirm Modal -->
    <div class="modal fade" id="checkoutConfirmModal" tabindex="-1" aria-labelledby="checkoutConfirmModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header text-center">
                    <h1 class="modal-title fs-5 text-center w-100" id="checkoutConfirmModalLabel">Confirm your order</h1>
                </div>
                <div class="modal-body">
                    <h5 class="title">Order Summary</h5>
                    <h5 class="order-title">SW23-003454</h5>
                    <div class="contents">
                        <table border="0" class="table mt-4">
                            <tr>
                                <td class="text-start bolder">Email Address:</td>
                                <td class="text-end"></td>
                            </tr>
                            <tr>
                                <td class="text-start bolder">Contact Name:</td>
                                <td class="text-end"></td>
                            </tr>
                            <tr>
                                <td class="text-start bolder">Delivery Date:</td>
                                <td class="text-end text-red">SATURDAY-28/10/2024</td>
                            </tr>
                            <tr>
                                <td class="text-start bolder">Total Items:</td>
                                <td class="text-end">2</td>
                            </tr>
                            <tr>
                                <td class="text-start bolder">Delivery Charges:</td>
                                <td class="text-end">$2.00</td>
                            </tr>
                            <tr>
                                <td class="text-start bolder">Sub Total:</td>
                                <td class="text-end">$2.00</td>
                            </tr>
                            <tr>
                                <td class="text-start bolder">Amount:</td>
                                <td class="text-end">$2.00</td>
                            </tr>
                            <tr>
                                <td class="text-start bolder">GST:</td>
                                <td class="text-end">$2.00</td>
                            </tr>
                            <tr>
                                <td class="text-start bolder">Total Amount:</td>
                                <td class="text-end text-red total-amount">$2.00</td>
                            </tr>
                            <tr>
                                <td class="text-start bolder">Remarks:</td>
                                <td class="text-end"></td>
                            </tr>
                            
                        </table>
                        <button type="submit" class="btn checkout-submit w-100 mt-2" >Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideFillter from '../components/products/SideFillter.vue';
import ProductCard from '../components/products/ProductCard.vue';
export default {
    name: 'HomePage',
    components: {
        SideFillter,
        ProductCard
    }
}
</script>
  
<style lang="scss">
.checkout-page {
    .add-items {
        border-radius: 50px;
        background-color: #EA0A2A;
        color: #fff;
        font-family: 'Kalam', cursive;
    }

    .item-headers {
        h5.title {
            font-family: 'Kalam', cursive;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .item {
        border-bottom: 1px solid #62626261;

        .remove-btn {
            color: #EA0A2A;

            .btn {
                width: 13px;
                height: 12px;
                color: #fff;
            }
        }

        .item-details {
            .image {
                max-width: 60px;
            }

            .name {
                font-size: 13px;
                font-weight: 500;
            }
        }

        .price {
            font-size: 16px;
            font-weight: 500;
        }

        .uom {
            font-size: 16px;
            font-weight: 500;
        }

        .total {
            font-size: 16px;
            font-weight: 500;
        }

        .qty {
            .qty-label {
                font-size: 14px;
            }

            .qtybtn {
                width: 55px;
                border: none;
                background: #0033A0;
                height: 32px;
                color: #FACD01;
                font-size: 22px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.min {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &.max {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            .qty-input {
                width: 66px;
                border-radius: 0;
                text-align: center;
            }
        }
    }

    .right-panel {
        .title {
            font-family: 'Kalam', cursive;
            font-size: 16px;
            font-weight: 600;
        }

        .table {
            td {
                width: 50%;
                font-size: 16px;
                background-color: #F6F6F6;
                letter-spacing: 0.6px;
                border: 1px solid rgb(24, 24, 24);
                font-weight: 500;
                color: rgb(0, 0, 0);

                &.blue-color {
                    color: #0033A0;
                    font-weight: 600;
                }

                &.ft-text {
                    font-size: 18px;
                }

                &.full-total {
                    color: #EA0A2A;
                    font-size: 23px;
                    font-weight: 600;
                }
            }
        }
    }

    .checkout-submit {
        background-color: #0033A0;
        color: #FACD01;
        border-radius: 50px;
        height: 50px;
        font-size: 20px;
        font-family: 'Kalam', cursive;
    }

    

}

#checkoutConfirmModal{
    .modal-header{
        background-color: #FACD01;
        color: #fff;
        font-family: 'Kalam', cursive;
    }

    .modal-body{
        .title{
            font-size: 23px;
            font-family: 'Kalam', cursive;
            font-weight: 600;
        }

        .order-title{
            font-size: 18px;
            font-weight: 600;
        }
    }
    .table{
        td{
            font-size: 18px;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 50%;
            &.bolder{
                font-weight: 600;
            }
            &.text-red{
                color: #EA0A2A;
            }

            &.total-amount{
                font-size: 25px;
                font-weight: 600;
            }
        }
    }
}

.checkout-submit {
        background-color: #0033A0;
        color: #FACD01;
        border-radius: 50px;
        height: 50px;
        font-size: 20px;
        font-family: 'Kalam', cursive;
    }

</style>
    